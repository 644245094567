<template>
  <layout style="background: #f5f5f5;" :title="$route.name" :options="options">
    <Filtros />
    <Lista v-if="get_enderecos.docs.length" />
    <ListagemVazia v-else />
    <ModalView />
    <ModalCreate ref="modalCreateAddress" />
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import Lista from "../components/Lista.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Filtros,
    Lista,
    ListagemVazia,
    ModalView
  },
  computed: {
    ...mapGetters(["get_enderecos", "get_enderecos_filtros", "getLoggedUser"])
  },
  methods: {
    ...mapActions(["abre_modal_view_endereco", "listar_enderecos"])
  },
  created() {
    this.listar_enderecos();
  }
};
</script>
